/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import './styles/header.scss';
import './styles/footer.scss';

import * as tradFr from '../translations/fr.json';
import * as tradEn from '../translations/en.json';
import * as tradMonths from '../translations/months.json';

var language = 'fr';

function switchLanguage(lang) {
    localStorage.setItem('language', lang);
    language = lang;
    processFlags(false);
    setTexts();
}

function setTexts() {
    var data = null;
    switch(language) {
        case 'en':
            data = tradEn;
            break;
        case 'fr':
        default:
            data = tradFr;
            break
    }
    // Changement textes par id
    const texts = document.getElementsByClassName('switchable');
    for(let item of texts) {
        item.innerHTML = data[item.id];
    }
    const textsBis = document.getElementsByClassName('switchable-data');
    for(let item of textsBis) {
        item.innerHTML = tradMonths[language][item.getAttribute('data-id')];
    }
    const textSubmit = document.getElementsByClassName('switchable-submit');
    for(let item of textSubmit) {
        item.value = data.submit;
    }
}

function processFlags(addListener) {
    const flags = document.getElementsByClassName('flag');
    for(let item of flags) {
        item.id === language ?
            item.classList.remove('blur')
            :
            item.classList.add('blur');
        addListener && item.addEventListener('click', () => {switchLanguage(item.id)});
    }
}

window.onload = () => {
    let lang = localStorage.getItem('language');
    if(lang) {
        language = lang;
    } else {
        localStorage.setItem('language', 'fr');
    }

    processFlags(true);
    setTexts();
}
